<template>
<div id="Students">

<div>
  <div class="pa-5 secondary elevation-5 bg-accent-card">
    <p class="mt-5 display-1">Students
      <v-btn color="accent" style="margin-top: -15px" @click="$router.push('/addstudent')" fab dark absolute right >
        <v-icon> add </v-icon>
      </v-btn>
    </p>
  </div>
</div>  


<datatablevue :tableData="tableData"></datatablevue>

</div>
</template>

<script>
const datatablevue = () => import(/* webpackChunkName: "datatablevue" */ '../../components/shared/dataTable');


export default {

  name: 'Students',

  components: {
    datatablevue
  },

  data(){return{

    // table headers
    headers: [ 
      { text: 'Id', value: 'studentID' }, 
      { text: 'Name', value: 'name' },{ text: 'Class', value: 'class' }, 
      { text: 'Course', value: 'course' }, { text: 'School', value: 'school' },
    ],

  }},

  computed: {

    GetStudentData(){
      return this.$store.getters.GetAllStudents;
    },

    tableData(){
      return {'headers': this.headers, 'Data': this.GetStudentData}
    },

  },

}
</script>
